'use client';

import { VariableSizeList as List } from 'react-window';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import ShowMoreLayout from '@web/components/product-list/ShowMoreLayout';
import ProductCard from '@web/components/product-cards/ProductCard';

const ProductListMobile = ({ products, horizontal, height = 330 }) => {
  const [windowWidth, setWindowWidth] = useState(390);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth);
    }
  }, []);
  const Row = ({ index, style }) => (
    <Box
      style={{
        ...style,
        paddingLeft: index !== 0 ? '8px' : '0px',
        paddingRight: index === products.length - 1 ? '0px' : '8px',
      }}
    >
      {index < products.length && <ProductCard product={products[index]} />}
    </Box>
  );

  return (
    <Box>
      {horizontal ? (
        <div style={{ overflowX: 'hidden' }}>
          <List
            height={height}
            itemCount={products.length + 1}
            itemSize={(index) =>
              index === products.length ? 8 : windowWidth / 2 + 8
            }
            layout="horizontal"
            width={windowWidth}
          >
            {Row}
          </List>
        </div>
      ) : (
        <ShowMoreLayout number={2} />
      )}
    </Box>
  );
};

export default ProductListMobile;
