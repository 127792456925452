'use client';

import { Box, Button, Grid } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import ProductCard from '@web/components/product-cards/ProductCard';

export default function ShowMoreLayout({ products, number }) {
  const [rows, setRows] = useState(1);
  return (
    <Box>
      <Grid container spacing={2}>
        {products.slice(0, number * rows).map((item) => (
          <Grid item xs={12 / number} key={item.id}>
            <ProductCard product={item} />
          </Grid>
        ))}
      </Grid>
      {number * rows < products.length && (
        <Button
          variant="text"
          size="large"
          fullWidth
          endIcon={<KeyboardArrowDownIcon />}
          onClick={() => setRows(rows + 1)}
        >
          View More
        </Button>
      )}
    </Box>
  );
}
